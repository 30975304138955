<template>
  <b-modal v-model="showModal"
           aria-label=""
           body-class="modal-form"
           modal-class="modal-style-one modal-background-white questionnaire"
           size="lg"
           title="Options"
           centered
           @ok="set"
           @shown="init"
           no-close-on-backdrop
  >

  <b-row>
      <b-col>
        <label>Period</label>
        <v-select v-model="form.period"
                  :options="periodOptions"
                  :reduce="(item) => item.value"
                  placeholder="Select Period"/>
      </b-col>

      <b-col v-if="isIntroducerAdmin">
        <label>Users</label>

        <IntroducerUserSelect
            v-model="form.introducer"
            :placeholder="'All of your users'"
            @selected="selected"
            />

      </b-col>

    </b-row>



    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button
            class="btn btn-backwards white-border"
            @click="cancel">Cancel
        </button>
        <button
            class="btn btn-forwards"
            @click="ok">Ok
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { options } from "@/views/introducer/reports/commissions/modals/options";
import IntroducerUserSelect from "@/components/common/other/form/IntroducerUsersSelect";

export default {
  name: 'ConfigModal',
  mixins: [options],
  components: {
    vSelect,
    IntroducerUserSelect,
  },
  props: ['value','formData','isIntroducerAdmin'],
  data() {
    return {
      form:{
        period: null,
        introducer: null,
        introducer_name: null,
      },
    }
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
  },
  methods: {
    selected(item){
      this.form.introducer_name = item.label
    },
    init(){
      this.form = JSON.parse(JSON.stringify(this.formData));
    },
    set () {
      this.$emit('change', this.form)
    }
  }
}
</script>
