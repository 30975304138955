export const options = {
    data() {
        return {
            frequencyOptions: [
                {
                    value: 'W-MON',
                    label: 'Weekly'
                },
                {
                    value: 'D',
                    label: 'Daily'
                },
                {
                    value: 'QS',
                    label: 'Quarterly'
                },
                {
                    value: 'MS',
                    label: 'Monthly'
                },
                {
                    value: 'YS',
                    label: 'Yearly'
                }
            ],
            periodOptions: [
                {
                    value: 'Overall',
                    label: 'Overall'
                },
                {
                    value: 'Last 3 Months',
                    label: 'Last 3 Months'
                },
                {
                    value: 'Last 6 Months',
                    label: 'Last 6 Months'
                },
                {
                    value: 'Last 12 Months',
                    label: 'Last 12 Months'
                },
            ],
            allOptions: [
                {
                    value: false,
                    label: 'You only'
                },
                {
                    value: true,
                    label: 'All of your users'
                }
            ],
        }
    }

}
